import React, { useContext, useEffect, useState } from "react";
import SearchableSelect from "../global/SearchableSelect";
import { ConceptosContext } from "../../context/ConceptosContext";
import { OrdenesCambioContext } from "../../context/OrdenesCambioContext";
import FileUpload from "../folder/FileUpload";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";
import { getValue } from "../../utils";

const OrdenCambioForm = ({ handleCancel }) => {
  const [selected, setSelected] = useState(null);
  const [adjuntos, setAdjuntos] = useState([]);

  const { idProyecto } = useProyecto();
  const { idContrato } = usePreciario();

  const { conceptos, getConceptosContrato } = useContext(ConceptosContext);
  const { ordencambio, saveOrdenCambio, setPropertyOrdenCambio } =
    useContext(OrdenesCambioContext);

  useEffect(() => {
    return () => setAdjuntos([]);
  }, []);

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);

  const getImporte = () => {
    if (ordencambio?.cantidad && ordencambio?.precio_unitario) {
      const precio = parseFloat(ordencambio.precio_unitario);
      const cantidad = parseFloat(ordencambio.cantidad);
      if (!isNaN(precio) && !isNaN(cantidad)) {
        return (cantidad * precio).toFixed(2);
      }
    }
    return "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const importe = getImporte() || null;
    const conceptoNuevo = {
      importe,
      clave: ordencambio.clave,
      unidad: ordencambio.unidad,
      cantidad: ordencambio.cantidad,
      concepto: ordencambio.concepto,
      precio_unitario: ordencambio.precio_unitario,
    };
    ordencambio.idConceptoAnterior = selected?.value || null;
    ordencambio.conceptoNuevo = conceptoNuevo;
    ordencambio.adjuntos = Array.from(adjuntos);
    ordencambio.idProyecto = idProyecto;
    ordencambio.idContrato = idContrato;
    saveOrdenCambio(ordencambio);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Folio</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(ordencambio, "folio")}
        onChange={(e) => setPropertyOrdenCambio("folio", e.target.value)}
      />
      <label>Concepto Anterior</label>
      <SearchableSelect
        itemText="nombre"
        value={selected?.value}
        itemValue="idConcepto"
        modifier={setSelected}
        items={conceptos.map((concepto) => ({
          ...concepto,
          nombre: `[${concepto.clave}]: ${concepto.nombre}`,
        }))}
      />
      <label className="mt-3">Concepto Nuevo</label>
      <input
        type="text"
        placeholder="CLAVE"
        className="form-control mb-3"
        onChange={(e) => setPropertyOrdenCambio("clave", e.target.value)}
      />
      <textarea
        rows="3"
        type="text"
        placeholder="CONCEPTO"
        className="form-control mb-3"
        onChange={(e) => setPropertyOrdenCambio("concepto", e.target.value)}
      />
      <div className="row">
        <div className="col-3">
          <input
            type="text"
            placeholder="UNIDAD"
            className="form-control mb-3"
            onChange={(e) => setPropertyOrdenCambio("unidad", e.target.value)}
          />
        </div>
        <div className="col-3">
          <input
            type="text"
            placeholder="CANTIDAD"
            className="form-control mb-3"
            onChange={(e) => setPropertyOrdenCambio("cantidad", e.target.value)}
          />
        </div>
        <div className="col-3">
          <input
            type="text"
            placeholder="PRECIO"
            className="form-control mb-3"
            onChange={(e) =>
              setPropertyOrdenCambio("precio_unitario", e.target.value)
            }
          />
        </div>
        <div className="col-3">
          <input
            disabled
            type="text"
            placeholder="IMPORTE"
            value={getImporte()}
            className="form-control mb-3"
          />
        </div>
      </div>
      <div className="container-fluid">
        <FileUpload modifier={setAdjuntos} />
      </div>
      <div className="row mt-5">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default OrdenCambioForm;
