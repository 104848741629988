import moment from "moment";
import React from "react";
import useProyecto from "../../hooks/useProyecto";

const ReporteHeader = ({ reporte }) => {
  const { proyecto } = useProyecto();
  return (
    <div className="row">
      <table className="table">
        <thead className="border">
          <tr>
            <td className="col-12 col-md-3 px-0 py-0"></td>
            <td className="col-12 col-md-9 px-0 py-0">
              <table className="table border border-collapse mb-0 align-items-start">
                <thead>
                  <tr>
                    <th className="col-3 bold">OBRA</th>
                    <th className="col-3 fw-normal">{proyecto?.nombre}</th>
                    <th className="bg-primary text-white">FECHA INICIO</th>
                    <th className="fw-normal">
                      {moment(reporte?.fecha_inicio).utc().format("DD-MMM")}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: 200 }} className="col-3 bold">
                      FECHA REPORTE
                    </th>
                    <th className="col-3 fw-normal">{moment().utc().format("DD MMM")}</th>
                    <th
                      style={{ width: 200 }}
                      className="bg-primary text-white"
                    >
                      FECHA TERMINO
                    </th>
                    <th className="fw-normal">{moment(reporte?.fecha_fin).utc().format("DD-MMM")}</th>
                  </tr>
                </thead>
              </table>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default ReporteHeader;
