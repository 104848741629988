import React, { useContext } from "react";
import { ReportesContext } from "../../context/ReportesContext";

const ReporteForm = ({ handleCancel, handleCallback }) => {
  const { spinner, reporte, saveReporte, setPropertyReporte } =
    useContext(ReportesContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveReporte(reporte, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        value={reporte.nombre}
        className="form-control mb-3"
        onChange={(e) => setPropertyReporte("nombre", e.target.value)}
      />
      {isNaN(parseInt(reporte.idReporte)) && (
        <div className="row mb-3">
          <div className="col-6">
            <label>Desde</label>
            <div class="input-group mb-3">
              <span class="input-group-text">
                <i className="fa fa-calendar" />
              </span>
              <input
                type="date"
                value={reporte.fecha_inicio}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-6">
            <label>Hasta</label>
            <div class="input-group mb-3">
              <span class="input-group-text">
                <i className="fa fa-calendar" />
              </span>
              <input
                type="date"
                value={reporte.fecha_fin}
                className="form-control"
                disabled
              />
            </div>
          </div>
        </div>
      )}
      {isNaN(parseInt(reporte.idReporte)) && (
        <>
          <label>Resumen</label>
          <textarea
            rows="8"
            value={reporte.resumen}
            className="form-control mb-3"
            onChange={(e) => setPropertyReporte("resumen", e.target.value)}
          />
        </>
      )}
      <div className="row">
        <div className="col-6">
          <button type="button" disabled={spinner} onClick={handleCancel} className="btn w-100 text-muted">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" disabled={spinner} className="btn w-100 btn-primary">
            {spinner ? <div className="spinner-border" /> : "Guardar"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ReporteForm;
