import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PagosContext } from "../../context/PagosContext";
import TabGroup from "../common/TabGroup";
import Chart from "react-apexcharts";
import moment from "moment";

const GraficaFinanzas = ({ idProyecto, start_date, end_date, hideTabs, maxSemana }) => {
  const { proyecto, progresoObra, getProgresoObra } =
    useContext(ProyectosContext);
  const { pagos, getPagosProyecto } = useContext(PagosContext);
  const [selected, setSelected] = useState(1);
  const [pagosDots, setPagosDots] = useState(null);
  const [totalPagos, setTotalPagos] = useState(null);

  const tabs = [
    { title: "Mes", selecteds: false, value: 0 },
    { title: "Semana", selected: true, value: 1 },
    { title: "Día", selected: false, value: 2 },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, start_date, end_date]);

  useEffect(() => {
    if (Array.isArray(pagos)) {
      handleTotalPagos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagos]);

  useEffect(() => {
    if (totalPagos !== null) {
      handlePagosDots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPagos]);

  function fetchData() {
    getPagosProyecto(idProyecto, { start_date, end_date });
    getProgresoObra(idProyecto, { start_date, end_date });
  }

  function handleTotalPagos() {
    let total = 0;
    pagos.forEach((pago) => {
      total += parseFloat(pago.monto);
    });
    setTotalPagos(total);
  }

  function handlePagosDots() {
    const pagosMes = getPagosDots(pagos, 30);
    const pagosSemana = getPagosDots(pagos, 7);
    const pagosDia = getPagosDots(pagos, 1);
    setPagosDots({ pagosMes, pagosSemana, pagosDia });
  }

  function getTabs() {
    if (progresoObra && progresoObra !== null) {
      if (progresoObra.programadoMes.length === 0) {
        if (selected === 0) setSelected(1);
        return tabs.slice(1);
      }
    }
    return tabs;
  }

  function getPagosDots(pagos, interval) {
    const { fecha_inicio, fecha_fin } = proyecto;
    let fin = moment(fecha_fin).add(interval, "days");
    let inicio = moment(fecha_inicio);
    let dots = [];
    let acum = 0.0;

    let pagosSet = new Set();
    while (inicio.isBefore(fin)) {
      const current = moment(inicio).startOf("day");

      if (pagos.length === 0) {
        acum = 0;
      } else {
        let currentPagos = pagos.filter((pago) =>
          moment(pago.fecha_hora_autorizacion).isBefore(current)
        );
        // eslint-disable-next-line no-loop-func
        currentPagos.forEach((pago) => {
          if (!pagosSet.has(pago.idPago)) acum += parseFloat(pago.monto);
          pagosSet.add(pago.idPago);
        });
      }

      dots.push(
        (parseFloat(acum) / parseFloat(proyecto.autorizado)).toFixed(2)
      );
      inicio.add(interval, "days");
    }
    return dots;
  }

  function getProgramadoDots(realDia, programadoDia) {
    if (programadoDia.length >= realDia.length) {
      if(maxSemana && maxSemana !== null) return programadoDia.slice(0, maxSemana);
      return programadoDia;
    }
    let dots = [];
    let i = 0;
    while (i < realDia.length) {
      if (!programadoDia || i >= programadoDia.length) {
        dots.push(
          programadoDia
            ? parseFloat(programadoDia[programadoDia.length - 1]).toFixed(2)
            : 0
        );
      } else {
        dots.push(parseFloat(programadoDia[i]).toFixed(2));
      }
      i++;
    }
    if(maxSemana && maxSemana !== null) return dots.slice(0, maxSemana);
    return dots;
  }

  function getRealDots(realDia, programadoDia) {
    if (programadoDia.length < realDia.length) {
      if (maxSemana && maxSemana !== null) return realDia.slice(0, maxSemana);
      return realDia;
    }
    let dots = [];
    let i = 0;
    while (i < programadoDia.length) {
      let current = realDia[i];
      if (current && i < realDia.length) {
        dots.push(parseFloat(current).toFixed(2));
      } else {
        dots.push(0);
      }
      i++;
    }
    if (maxSemana && maxSemana !== null) return dots.slice(0, maxSemana);
    return dots;
  }

  function formatPagosDots(pagosData, programadoData) {
    if (programadoData.length > pagosData.length) {
      let currentLastItem = pagosData[pagosData.length - 1];
      let diff = programadoData.length - pagosData.length;
      for (let i = 0; i < diff; i++) {
        pagosData.push(
          isNaN(currentLastItem) ? 0 : parseFloat(currentLastItem).toFixed(2)
        );
      }
    }
    if (maxSemana && maxSemana !== null) return pagosData.slice(0, maxSemana);
    return pagosData;
  }

  function renderGrafica() {
    if (
      progresoObra &&
      progresoObra !== null &&
      proyecto !== null &&
      proyecto !== null &&
      pagosDots !== null
    ) {
      const {
        programadoDia,
        realDia,
        programadoMes,
        realMes,
        realSemana,
        programadoSemana,
      } = progresoObra;
      if (Array.isArray(realDia) && Array.isArray(programadoMes)) {
        const programadoData =
          selected === 0
            ? programadoMes
            : selected === 1
            ? programadoSemana
            : programadoDia;
        const realData =
          selected === 0 ? realMes : selected === 1 ? realSemana : realDia;
        const pagosData =
          selected === 0
            ? pagosDots.pagosMes
            : selected === 1
            ? pagosDots.pagosSemana
            : pagosDots.pagosDia;
        return (
          <>
            <Chart
              type="area"
              options={{
                colors: ["#007991", "#CC1B7F", "#FF8008", "#00FF00", "#FF0000"],
                dataLabels: {
                  enabled: false,
                  enabledOnSeries: 1,
                },
                legend: {
                  show: true,
                },
                xaxis: {
                  title: {
                    text: selected === 0 ? "Mes" : selected === 1 ? "Semana" : "Día",
                  },
                  labels: {
                    hideOverlappingLabels: true,
                    style: {
                      fontSize: "8px",
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: "Porcentaje completado",
                  },
                  labels: {
                    formatter: value => `${parseFloat(value) * 100}%`
                  }
                },
              }}
              series={[
                {
                  name: "Programado",
                  data: getProgramadoDots(realData, programadoData),
                },
                {
                  name: "Real",
                  data: getRealDots(realData, programadoData),
                },
                {
                  name: "Pagos",
                  data: formatPagosDots(pagosData, programadoData),
                },
              ]}
            />
            {!hideTabs && <TabGroup
              tabs={getTabs()}
              selected={selected}
              modifier={setSelected}
            />}
          </>
        );
      }
    }
  }

  return <div className="row">{renderGrafica()}</div>;
};

export default GraficaFinanzas;
