import React, { createContext, useContext, useReducer } from "react";
import {
  PROGRAMADO_DIA_RECEIVED,
  PROGRAMADO_MES_RECEIVED,
  PROGRAMADO_SEMANA_RECEIVED,
  REAL_DIA_RECEIVED,
  REAL_MES_RECEIVED,
  REAL_SEMANA_RECEIVED,
  RESET_PROGRESO_OBRA,
} from "../actions/types";
import ProyectosReducer from "../reducers/ProyectosReducer";
import AdjuntosService from "../services/AdjuntosService";
import ProgresosService from "../services/ProgresosService";
import ProyectosService from "../services/ProyectosService";
import {
  VIEW_OPTIONS,
  HIDE_OPTIONS,
  EDIT_PROYECTO,
  PROYECTOS_RECEIVED,
  CREATE_NUEVA_PROYECTO,
  SET_PROPERTY_PROYECTO,
  SINGLE_PROYECTO_RECEIVED,
} from "../types/proyectos";
import { ModalContext } from "./ModalContext";
import { AuthContext } from "./AuthContext";

const initialState = {
  proyectos: null,
  proyecto: null,
  options: null,
  showSpinner: false,
  progresoObra: null,
};

export const ProyectosContext = createContext(initialState);

export const ProyectosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProyectosReducer, initialState);

  const { user } = useContext(AuthContext);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getProyectos = (filters) => {
    ProyectosService.getProyectos(filters)
      .then((res) => {
        const { proyectos } = res.data;
        dispatch({ type: PROYECTOS_RECEIVED, payload: proyectos });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getAllProyectos = () => {
    ProyectosService.getAllProyectos()
      .then((res) => {
        const { proyectos } = res.data;
        dispatch({ type: PROYECTOS_RECEIVED, payload: proyectos });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleProyecto = (idProyecto) => {
    ProyectosService.getProyecto(idProyecto).then((res) => {
      const { proyecto } = res.data;
      if (user.permiso === "admin") {
        proyecto.permiso = user.permiso;
      }      
      dispatch({ type: SINGLE_PROYECTO_RECEIVED, payload: proyecto });
    });
  };

  const getProgresoObra = (idProyecto, filters) => {
    dispatch({ type: RESET_PROGRESO_OBRA });
    ProgresosService.getProgreso(idProyecto, filters).then((res) => {
      const { semana, mes, dia } = res.data.progreso;
      //Set progreso semana
      dispatch({ type: REAL_SEMANA_RECEIVED, payload: semana.real });
      dispatch({
        type: PROGRAMADO_SEMANA_RECEIVED,
        payload: semana.programado,
      });

      //Set progreso dia
      dispatch({ type: REAL_DIA_RECEIVED, payload: dia.real });
      dispatch({
        type: PROGRAMADO_DIA_RECEIVED,
        payload: dia.programado,
      });

      //Set progreso mes
      dispatch({ type: REAL_MES_RECEIVED, payload: mes.real });
      dispatch({
        type: PROGRAMADO_MES_RECEIVED,
        payload: mes.programado,
      });
    });
  };

  const createProyecto = () => {
    dispatch({
      type: CREATE_NUEVA_PROYECTO,
    });
  };

  const editProyecto = (proyecto) => {
    dispatch({ type: EDIT_PROYECTO, payload: proyecto });
  };

  const setPropiedadProyecto = (key, value) => {
    dispatch({ type: SET_PROPERTY_PROYECTO, payload: { key, value } });
  };

  const clearProyectos = () => {
    dispatch({ type: PROYECTOS_RECEIVED, payload: null });
  };

  const clearSingleProyecto = () => {
    dispatch({ type: SINGLE_PROYECTO_RECEIVED, payload: null });
  };

  const viewOptions = (proyecto) => {
    dispatch({ type: VIEW_OPTIONS, payload: proyecto });
  };

  const hideOptions = () => {
    dispatch({ type: HIDE_OPTIONS });
  };

  const postProyecto = (proyecto, callback) => {
    let service = ProyectosService.putProyecto;
    if (isNaN(parseInt(proyecto.idProyecto))) {
      service = ProyectosService.postProyecto;
    }
    const promises = [];
    if (proyecto.file && proyecto.file !== null) {
      const formData = AdjuntosService.getFormData(proyecto.file);
      promises.push(
        new Promise((resolve, reject) => {
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            proyecto.idAdjunto = idAdjunto;
            delete proyecto.file;
            resolve();
          });
        })
      );
    }
    Promise.all(promises).then(() => {
      service(proyecto)
        .then(() => {
          clearModal();
          success(`Proyecto ${proyecto.nombre} guardado.`);
          if (callback) callback();
        })
        .catch(alert);
    });
  };

  const deleteProyecto = (idProyecto, callback) => {
    ProyectosService.deleteProyecto(idProyecto)
      .then(() => {
        success("Proyecto eliminado.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <ProyectosContext.Provider
      value={{
        ...state,
        viewOptions,
        hideOptions,
        postProyecto,
        getProyectos,
        editProyecto,
        deleteProyecto,
        clearProyectos,
        createProyecto,
        getAllProyectos,
        getProgresoObra,
        getSingleProyecto,
        clearSingleProyecto,
        setPropiedadProyecto,
      }}
    >
      {children}
    </ProyectosContext.Provider>
  );
};
