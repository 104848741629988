import { useState } from "react";
import GoogleService from "../services/GoogleService";

const useGoogleDrive = () => {
  const [driveFolders, setDriveFolders] = useState(null);
  const [fetching, setFetching] = useState(false);

  const clearFolders = () => setDriveFolders(null);

  const handleAuth = () => {
    setFetching(false);
    GoogleService.getAuth().then((res) => {
      window.location = res.data;
    });
  };

  const fetchDriveFolders = (filters, callback) => {
    setFetching(true);
    GoogleService.getFolders(filters)
      .then((res) => {
        setFetching(false);
        if (!res.data.folders) return handleAuth();
        setDriveFolders(res.data.folders);
        if (typeof callback === "function") callback();
      })
      .catch(handleAuth);
  };

  const fetchSingleFolder = (google_drive_id, callback) => {
    GoogleService.getSingleFolder(google_drive_id).then((res) => {
      callback(res.data.folder);
    });
  };

  return {
    fetching,
    handleAuth,
    clearFolders,
    driveFolders,
    fetchSingleFolder,
    fetchDriveFolders,
  };
};

export default useGoogleDrive;
