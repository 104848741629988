import React, { useEffect, useState, useRef } from "react";
import Mensaje from "./Mensaje";
import moment from "moment";

const Entradas = ({ max, entradas, idProyecto, idAdjunto, scrollCallback }) => {
  const refContainer = useRef();
  const [reachedTop, setReachedTop] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (Array.isArray(entradas) && firstLoad) {
      const container = refContainer.current;
      refContainer.current.scrollTo({
        top: container.offsetHeight * 10,
      });
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entradas]);

  const renderEntradas = () => {
    if (Array.isArray(entradas)) {
      if (entradas.length === 0) {
        return (
          <div className="row">
            <p className="px-0" style={{ color: "#acacac" }}>
              No hay comentarios creados
            </p>
          </div>
        );
      }
      let prevEntrada = null;
      const entradasRender = [];
      entradas.forEach((entrada) => {
        if (prevEntrada === null) {
          prevEntrada = entrada;
          if(prevEntrada.Adjunto !== null) {
            prevEntrada.adjuntos = [prevEntrada.Adjunto];
          } else {
            prevEntrada.adjuntos = [];
          }
        } else {
          if (prevEntrada.idUsuario === entrada.idUsuario) {
            // If either one doesn't have content or neither
            if (
              (prevEntrada.contenido === null && entrada.contenido !== null) ||
              (prevEntrada.contenido !== null && entrada.contenido === null) ||
              (prevEntrada.contenido === null && entrada.contenido === null)
            ) {
              const prevTime = moment(prevEntrada.createdAt).format("HH:mm");
              const currTime = moment(entrada.createdAt).format("HH:mm");
              if (prevTime === currTime) {
                if(entrada.Adjunto !== null) {
                  prevEntrada.adjuntos.push(entrada.Adjunto);
                }
                if(entrada.contenido !== null && prevEntrada.contenido === null) {
                  prevEntrada.contenido = entrada.contenido;
                }
              } else {
                entradasRender.push(prevEntrada);
                prevEntrada = null;
              }
            } else {
              entradasRender.push(prevEntrada);
              prevEntrada = null;  
            }
          } else {
            entradasRender.push(prevEntrada);
            prevEntrada = null;
          }
        }
      });
      let previous;
      let follow;
      let fechaAnterior;
      return entradas.map((entrada) => {
        if (previous) {
          fechaAnterior = previous.createdAt;
          follow = previous.idUsuario === entrada.idUsuario;
        }
        previous = entrada;
        if(entrada.Adjunto !== null) entrada.adjuntos = [entrada.Adjunto];
        else entrada.adjuntos = [];
        return (
          <Mensaje
            key={`mensaje-${entrada.idEntrada}`}
            fechaAnterior={fechaAnterior}
            idProyecto={idProyecto}
            idAdjunto={idAdjunto}
            entrada={entrada}
            follow={follow}
          />
        );
      });
    }
    return <div className="spinner-border"></div>;
  };

  const handleScroll = () => {
    const container = refContainer.current;
    const scrollTop = container.scrollTop;
    if (scrollTop === 0) {
      if (entradas.length < max) {
        let offset = entradas.length;
        if (typeof scrollCallback === "function") {
          scrollCallback(offset);
        }
      } else {
        setReachedTop(true);
      }
    }
  };

  return (
    <div
      id="entradas"
      ref={refContainer}
      onScroll={handleScroll}
      className="container-fluid h-100 pb-2"
    >
      {reachedTop && (
        <p className="text-center">No hay entradas más antiguas.</p>
      )}
      {renderEntradas()}
    </div>
  );
};

export default Entradas;
