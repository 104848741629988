import React, { Fragment, useContext } from "react";
import { FILES_ENDPOINT, isSameDate } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import AdjuntoEntrada from "./AdjuntoEntrada";
import moment from "moment";

const Mensaje = ({ retraso, entrada, fechaAnterior }) => {
  const { user } = useContext(AuthContext);

  function isFollowUp() {
    return (
      isSameDate(fechaAnterior, entrada.createdAt) && !retraso && fechaAnterior
    );
  }

  function renderUserImg() {
    if (entrada.Usuario && entrada.Usuario !== null) {
      if (entrada.Usuario.Adjunto !== null) {
        const { Adjunto } = entrada.Usuario;
        return (
          <img
            src={`${FILES_ENDPOINT}/${Adjunto.idAdjunto}.${Adjunto.tipo}`}
            className="bitacoraImage mt-3"
            style={{ boxShadow: "0 0 8px rgba(0,0,0,.2)", borderRadius: "50%" }}
            alt={Adjunto.nombre}
          />
        );
      }
    }
    return <i className="fa fa-user-circle fa-2x d-md-block d-inline"></i>;
  }

  function renderUser() {
    if (entrada.idUsuario !== user.idUsuario) {
      return (
        <div className="col-12 col-md-1 my-2 my-md-0">
          <div className="row align-items-center">
            <div className="col-md-12 col-1">{renderUserImg()}</div>
            <div className="col-md-12 col-11 d-md-none">
              <span className="d-md-none d-inline-block ms-2">
                {renderName()}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return <div className="col"></div>;
  }

  function renderContenido() {
    if (entrada && entrada !== null) {
      if (entrada.contenido !== null && entrada.contenido !== "") {
        return <div className="card-text">{entrada.contenido}</div>;
      }
    }
  }

  function renderAdjuntos() {
    if (entrada && entrada !== null) {
      if (Array.isArray(entrada.adjuntos)) {
        return entrada.adjuntos
          .filter((adjunto) => adjunto && adjunto !== null)
          .map((adjunto) => (
            <AdjuntoEntrada key={adjunto.idAdjunto} adjunto={adjunto} />
          ));
      }
    }
  }

  function renderPermiso() {
    if (entrada.Usuario.PermisoProyectos) {
      return (
        <span className="badge ms-2 small text-capitalize badge-pill bg-dark">
          {entrada.Usuario.PermisoProyectos[0]?.Permiso?.nombre}
        </span>
      );
    }
  }

  function renderName() {
    if (
      entrada.Usuario &&
      entrada.Usuario !== null &&
      entrada.idUsuario !== user.idUsuario
    ) {
      return (
        <span className="small mb-2">
          {entrada.Usuario.nombre}
          {renderPermiso()}
        </span>
      );
    }
  }

  function renderFecha() {
    if (!isFollowUp()) {
      return (
        <div className="row mb-2 mb-md-3">
          <div className="container text-center my-2">
            <span className="bg-light small border p-2 shadow-sm round5">
              {moment(entrada.createdAt).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
      );
    }
  }

  const renderRubro = () => {
    let message = "";
    if (entrada.SubDestajo && entrada.SubDestajo !== null) {
      message = entrada.SubDestajo.nombre;
    } else if (entrada.Destajo && entrada.Destajo !== null) {
      message = entrada.Destajo;
    } else if (entrada.Partida && entrada.Partida !== null) {
      message = entrada.Partida.nombre;
    }
    if (message !== "") {
      return (
        <span
          className={`bg-${
            entrada.retraso ? "danger" : "black"
          } badge badge-pill mb-1`}
        >
          {message}
        </span>
      );
    }
  };

  const renderCol = () => {
    if (entrada.idUsuario !== user.idUsuario) {
      return <div className="col" />;
    }
  };

  return (
    <Fragment>
      {renderFecha()}
      <div className="row mb-2 mb-md-3" style={{ minHeight: 70 }}>
        {renderUser()}
        <div className="col-11 col-md-10" style={{ minWidth: 250 }}>
          <div className="d-none d-md-block mb-2">{renderName()}</div>
          <div
            className={entrada.idUsuario === user.idUsuario ? "text-end" : ""}
          >
            {renderRubro()}
          </div>
          <div
            className={`card shadow-sm msg-card py-3 ${
              entrada.idUsuario === user.idUsuario
                ? "bg-primary text-white me-0 ms-auto"
                : "bg-white ms-0 me-auto"
            }`}
            style={{
              maxWidth: entrada.adjuntos.length <= 1 ? "600px" : "100%",
            }}
          >
            <div className="container-fluid">
              {renderAdjuntos()}
              {renderContenido()}
            </div>
            <div className="container-fluid mt-2">
              <p className="mb-0 small" style={{ minWidth: 42 }}>
                {entrada ? moment(entrada.createdAt).format("HH:mm") : ""}
              </p>
            </div>
          </div>
        </div>
        {renderCol()}
      </div>
    </Fragment>
  );
};

export default Mensaje;
