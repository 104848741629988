import { getArgs } from "../utils";
import api from "./api";

const route = "/google";

const GoogleService = {
  getAuth: () => api.get(`${route}/auth`),
  getFolders: (filters) => api.get(`${route}/folders?${getArgs(filters)}`),
  getSingleFolder: (fileId) => api.get(`${route}/folders/${fileId}`),
  postCode: code => api.post(`${route}/auth/callback`, { code }),
  autoSync: idProyecto => api.post(`${route}/autosync/${idProyecto}`),
};

export default GoogleService;
