import React, { useEffect, useState } from "react";
import GoogleService from "../../services/GoogleService";

const ProyectoFolderRow = ({ folder, handleSync, handleUnsync }) => {
  const [driveFolder, setDriveFolder] = useState(null);

  useEffect(() => {
    if (folder.google_drive_id !== null) {
      GoogleService.getSingleFolder(folder.google_drive_id).then((res) => {
        setDriveFolder(res.data.folder);
      });
    }
  }, [folder]);

  return (
    <div className="p-3 border-top">
      <div className="row align-items-center">
        <div className="col-6">{folder.nombre}</div>
        <div className="col-4">
          {folder.google_drive_id !== null && (
            <div className="card border bg-light p-2">
              <div className="container-fluid">
                <i className="fa fa-folder me-1" /> {driveFolder?.name}{" "}
                <button onClick={() => handleUnsync(folder)} className="btn d-inline me-0 ms-auto">
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-2 text-end">
          <button className="btn" onClick={() => handleSync(folder)}>
            <i className="fa fa-sync text-primary" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProyectoFolderRow;
