import {
  SHOW_MODAL,
  SET_RESPONSE,
  HIDE_MODAL,
  CLEAR,
  MODAL_COMPONENT,
  SHOW_ALERT,
  SHOW_SUCCESS,
  CLEAR_ALERT,
  CLEAR_SUCCESS,
  CLEAR_MODAL,
} from "../types/modal";
import { hideModal } from "../utils";

const INITIAL_STATE = {
  response: "",
  show: false,
  content: "",
  callback: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL: {
      const { title, content, callback } = payload;
      return {
        ...state,
        title,
        content,
        show: true,
        onSuccess: callback,
        onCancel: hideModal,
      };
    }
    case HIDE_MODAL:
      return { ...state, show: false };
    case SET_RESPONSE:
      return { ...state, response: payload };
    case MODAL_COMPONENT:
      return {
        ...state,
        show: true,
        component: payload.component,
        title: payload.title,
        onClose: payload.onClose,
        onSuccess: payload.callback,
      };
    case CLEAR_MODAL:
      return {
        ...state,
        show: false,
        showModal: false,
      };
    case SHOW_ALERT:
      return { ...state, showAlert: true, alertContent: payload };
    case CLEAR_ALERT:
      return { ...state, showAlert: false, alertContent: "" };
    case CLEAR:
      return { ...INITIAL_STATE };
    case SHOW_SUCCESS:
      return { ...state, showSuccess: true, successContent: payload };
    case CLEAR_SUCCESS:
      return { ...state, showSuccess: false, successContent: "" };
    default:
      return { ...state };
  }
};
