import React, { useContext, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import useProyecto from "../hooks/useProyecto";
import { ModalContext } from "../context/ModalContext";
import { ReportesContext } from "../context/ReportesContext";
import ReporteForm from "../components/reportes/ReporteForm";
import ConfirmDelete from "../components/global/ConfirmDelete";

const Reportes = () => {
  const { reportes, setReporte, getReportes, deleteReporte } =
    useContext(ReportesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { idProyecto } = useProyecto();

  useEffect(() => {
    fetchReportes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportes = () => getReportes(idProyecto);

  const handleEdit = (reporte) => {
    setReporte(reporte);
    modalComponent("Editar Reporte", <ReporteForm handleCancel={clearModal} handleCallback={fetchReportes} />);
  };

  const handleDelete = (reporte) => {
    modalComponent(
      "Eliminar Reporte",
      <ConfirmDelete
        message={`¿Estás seguro que deseas eliminar el reporte ${reporte.nombre}?`}
        handleDelete={() => deleteReporte(idProyecto, reporte.idReporte, fetchReportes)}
        handleCancel={clearModal}
      />
    );
  };

  const renderReportes = () => {
    if (Array.isArray(reportes)) {
      if(reportes.length === 0) {
        return <p>Aún no hay reportes creados para este proyecto.</p>;
      }
      return (
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-light border bold">
              <tr>
                <th>#ID</th>
                <th>Nombre</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {reportes.map((reporte) => (
                <tr key={reporte.idReporte} className="border-bottom">
                  <td>{reporte.idReporte}</td>
                  <td>{reporte.nombre}</td>
                  <td>
                    {moment(reporte.fecha_inicio).utc().format("DD MMM YYYY")}
                  </td>
                  <td>
                    {moment(reporte.fecha_fin).utc().format("DD MMM YYYY")}
                  </td>
                  <td>
                    <Link
                      to={`/obra/${idProyecto}/reportes/${reporte.idReporte}`}
                      className="btn btn-sm btn-outline-primary me-2"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                    <button
                      className="btn btn-sm btn-outline-primary me-2"
                      onClick={() => handleEdit(reporte)}
                    >
                      <i className="fa fa-edit" />
                    </button>
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => handleDelete(reporte)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <div className="spinner-border" />;
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center mb-3">
        <div className="col-10">
          <h1 className="h2 mb-0">Reportes</h1>
        </div>
        <div className="col-2 text-end">
          <Link to="./generador" className="btn btn-primary">
            <i className="fa fa-plus" />
          </Link>
        </div>
      </div>
      <div className="card shadow px-4 py-3">{renderReportes()}</div>
    </div>
  );
};

export default Reportes;
