import React, { useContext, useEffect } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import QRCode from "react-qr-code";
import { MenuContext } from "../context/MenuContext";

const WhatsApp = () => {
  const { whatsapp_qrcode, whatsapp_status } = useContext(AppConfigContext);
  const { selectTabs, setSelected } = useContext(MenuContext);

  useEffect(() => {
    selectTabs();
    setSelected({ title: "WhatsApp" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderQrCode = () => {
    if (
      whatsapp_status !== "connected" &&
      whatsapp_qrcode &&
      whatsapp_qrcode !== null
    ) {
      return <QRCode value={whatsapp_qrcode} />;
    }
  };

  const renderStatus = () => {
    if (whatsapp_status === "disconnected") {
      return (
        <div>
          <span className="badge badge-pill bg-danger">Desconectado</span>
          <p className="mt-2">
            Debes vincular tu dispositivo a Arqvisor Bot para continuar usando
            tus beneficios.
          </p>
          <p>
            Escanea el código QR usando WhatsApp desde tu dispositivo.
          </p>
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-outline-primary"
            href="https://faq.whatsapp.com/1317564962315842/?locale=es_LA&cms_platform=web"
          >
          <i className="fas fa-external-link-alt" />  Aprender más
          </a>
        </div>
      );
    }
    return (
      <span
        className={`badge d-inline mb-3 text-capitalize badge-pill ${
          whatsapp_status === "connected" ? "bg-success" : "bg-danger"
        }`}
      >
        {whatsapp_status}
      </span>
    );
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 pb-2 mb-3 border-bottom">
        Conectar Arqvisor WhatsApp Bot
      </h1>
      <div className="row">
        <div className="col-12 col-md-8 col-xl-6">
          <div className="card p-3 shadow">
            <div className="row">
              <div className="col-6">
                <h2 className="h4">Estado del Bot</h2>
                {renderStatus()}
              </div>
              <div className="col-6 text-center">{renderQrCode()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsApp;
