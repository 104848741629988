import api from "./api";

const route = "/appconfig";

const AppConfigService = {
  getAppConfig: () => api.get(route),
  getAdminAppConfig: () => api.get(`${route}/admin`),
  putAppconfig: (key, value) => api.put(route, { key, value }),
  regenerateAssistant: (idProyecto) => api.put(`${route}/assistant`, { idProyecto}),
};

export default AppConfigService;
