import React, { createContext, useContext, useReducer } from "react";
import {
  SELECT_TABS,
  SET_BUTTONS,
  SET_SELECTED,
  SET_SUBTITLE,
  SET_TITLE,
  SET_TITLE_HANDLER,
  TOGGLE_SETTINGS,
} from "../types/menu";
import { homeTabs, obraTabs } from "../utils/menu";
import { AppConfigContext } from "./AppConfigContext";
import MenuReducer from "../reducers/MenuReducer";

const initialState = {
  title: "",
  onClickTitle: () => {},
  subtitle: "",
  tabs: [],
  selected: "",
  showSettings: false,
  buttons: null,
};

export const MenuContext = createContext(initialState);

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const appConfig = useContext(AppConfigContext);

  const setButtons = (buttons) => {
    dispatch({ type: SET_BUTTONS, payload: buttons });
  };

  const setTitle = (payload) => {
    dispatch({ type: SET_TITLE, payload });
  };

  const setHandleClickTitle = (callback) => {
    dispatch({ type: SET_TITLE_HANDLER, payload: callback });
  };

  const setSubtitle = (payload) => {
    dispatch({ type: SET_SUBTITLE, payload });
  };

  const selectTabs = (idProyecto) => {
    if (!idProyecto) {
      dispatch({ type: SELECT_TABS, payload: homeTabs(appConfig) });
    } else {
      let tabs = obraTabs(idProyecto, appConfig);
      dispatch({ type: SELECT_TABS, payload: tabs });
    }
  };

  const setSelected = (tab) => {
    dispatch({ type: SET_SELECTED, payload: tab });
  };

  const toggleSettings = () => {
    dispatch({ type: TOGGLE_SETTINGS });
  };
  return (
    <MenuContext.Provider
      value={{
        ...state,
        setTitle,
        setSubtitle,
        setButtons,
        selectTabs,
        setSelected,
        toggleSettings,
        setHandleClickTitle,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
