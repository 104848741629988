import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { FolderContext } from "../../context/FolderContext";
import GoogleService from "../../services/GoogleService";
import CheckBox from "../../components/common/CheckBox";
import { MenuContext } from "../../context/MenuContext";
import useProyecto from "../../hooks/useProyecto";
import { parse } from "query-string";
import ProyectoFolderRow from "../../components/drive/ProyectoFolderRow";
import { ModalContext } from "../../context/ModalContext";
import ConnectDriveFolder from "../../components/drive/ConnectDriveFolder";
import useGoogleDrive from "../../hooks/useGoogleDrive";

const AjustesGoogle = () => {
  const [selectedDriveFolder, setSelectedDriveFolder] = useState(null);
  const [savedFolder, setSavedFolder] = useState(null);
  const [query, setQuery] = useState("");

  const { proyecto, postProyecto, getSingleProyecto, setPropiedadProyecto } =
    useContext(ProyectosContext);
  const { folders, updateFolder, getFoldersProyecto } =
    useContext(FolderContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { google_authenticated } = useContext(AppConfigContext);
  const { selectTabs } = useContext(MenuContext);
  const { idProyecto } = useProyecto();

  const {
    handleAuth,
    driveFolders,
    clearFolders,
    fetchSingleFolder,
    fetchDriveFolders,
  } = useGoogleDrive();

  useEffect(() => {
    handleURLParams();
    if (!idProyecto) selectTabs();
    if (idProyecto) getFoldersProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (proyecto && proyecto !== null) {
      if (proyecto.google_drive_id !== null) {
        fetchSingleFolder(proyecto.google_drive_id, setSavedFolder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyecto]);

  useEffect(() => {
    fetchDriveFolders({ query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const callback = () => setPropiedadProyecto("google_drive_id", null);

  const saveCallback = () => {
    getSingleProyecto(idProyecto);
    clearFolders();
  };

  const saveFolderCallback = () => {
    getFoldersProyecto(idProyecto);
    clearModal();
  };

  const handleReset = () => {
    setSavedFolder(null);
    fetchDriveFolders({}, callback);
  };

  const handleSave = () => {
    postProyecto(
      { ...proyecto, google_drive_id: selectedDriveFolder },
      saveCallback
    );
  };

  const handleURLParams = () => {
    const searchParams = parse(window.location.search);
    if (searchParams.code) {
      GoogleService.postCode(searchParams.code);
    }
  };

  const handleSync = (folder) => {
    modalComponent(
      "Conectar Folder",
      <ConnectDriveFolder
        folder={folder}
        handleCancel={clearModal}
        driveFolders={driveFolders}
        handleSave={handleUpdateFolder}
      />
    );
  };

  const handleUpdateFolder = (folder, google_drive_id) => {
    updateFolder(
      idProyecto,
      { ...folder, google_drive_id },
      saveFolderCallback
    );
  };

  const handleAutoSync = () => {
    modalComponent(
      "Auto Sincronizar",
      <div>
        <p>
          ¿Estás seguro que deseas autosincronizar las carpetas de Google Drive?
        </p>
        <p>
          Esta acción creará <span className="bold text-danger">todas</span> las
          carpetas en la lista del proyecto dentro de la carpeta prinicpal del
          proyecto.
        </p>
        <p>Esta acción NO se puede deshacer.</p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => {
                GoogleService.autoSync(idProyecto);
                clearModal();
              }}
              className="btn w-100 btn-primary"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="card p-3 shadow">
        {idProyecto ? (
          <>
            <div className="row border-bottom pb-2 mb-3 align-items-center">
              <div className="col-12 col-md-8">
                <h1 className="h2 mb-0">Google Drive</h1>
              </div>
              <div className="col-12 col-md-4 text-end">
                <button
                  className="btn text-primary"
                  onClick={fetchDriveFolders}
                >
                  <i className="fa fa-sync" />
                </button>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-md-9">
                <h3 className="h5">Folder Principal de Proyecto</h3>
              </div>
              {Array.isArray(driveFolders) && selectedDriveFolder && (
                <div className="col-12 col-md-3 text-end">
                  <button onClick={handleSave} className="btn btn-primary">
                    Guardar
                  </button>
                </div>
              )}
            </div>
            {savedFolder === null && proyecto?.google_drive_id && (
              <div className="spinner-border" />
            )}
            {savedFolder !== null && (
              <div className="card p-3 border bg-light">
                <div className="row align-items-center">
                  <div className="col-9">
                    <i className="fa fa-folder me-1" /> {savedFolder.name}
                  </div>
                  <div className="col-3 text-end">
                    <button onClick={handleReset} className="btn btn-sm">
                      <i className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </div>
            )}
            {proyecto.google_drive_id === null && (
              <div>
                <label>Buscar folder por nombre</label>
                <input
                  type="text"
                  value={query}
                  className="form-control border mb-3"
                  onChange={(e) => setQuery(e.target.value)}
                />
                {driveFolders?.map((folder) => (
                  <div key={folder.id} className="p-3 border-top">
                    <CheckBox
                      checked={selectedDriveFolder === folder.id}
                      modifier={() => setSelectedDriveFolder(folder.id)}
                    />
                    {folder.name}
                  </div>
                ))}
              </div>
            )}

            <div className="row align-items-center my-3">
              <div className="col-9">
                <h3 className="h5 mb-0">Folders de Proyecto</h3>
              </div>
              <div className="col-3 text-end">
                <button onClick={handleAutoSync} className="btn btn-primary">
                  <i className="fa fa-sync me-1" /> Auto
                </button>
              </div>
            </div>

            {folders?.map((folder) => (
              <ProyectoFolderRow
                key={folder.idFolder}
                folder={folder}
                handleSync={handleSync}
                handleUnsync={(folder) => handleUpdateFolder(folder, null)}
              />
            ))}
          </>
        ) : (
          <>
            <div className="row border-bottom pb-2 mb-3 align-items-center">
              <div className="col-12 col-md-8">
                <h1 className="h2 mb-0">Google Drive</h1>
              </div>
              <div className="col-12 col-md-4 text-end">
                {google_authenticated ? (
                  <span className="badge badge-pill bg-success mb-2 d-inline">
                    Conectado
                  </span>
                ) : (
                  <span className="badge badge-pill bg-danger mb-2 d-inline">
                    Desconectado
                  </span>
                )}
              </div>
            </div>
            <button className="btn w-100 btn-primary" onClick={handleAuth}>
              <i className="fa fa-sync me-1" /> Autenticar
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AjustesGoogle;
