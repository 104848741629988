import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { FolderContext } from "../../context/FolderContext";
import { ModalContext } from "../../context/ModalContext";
import { Document, Page, pdfjs } from "react-pdf";
import { S3_ENDPOINT } from "../../utils";
import AdjuntoForm from "./AdjuntoForm";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AdjuntoCard = ({ size, adjunto }) => {
  const [outline, setOutline] = useState("");
  const [source, setSource] = useState("");
  const [src, setSrc] = useState("");

  const adjuntosContext = useContext(AdjuntosContext);

  const { platform } = useContext(CapacitorContext);

  // const platform =

  const { deleteAdjunto, updateSingleAdjunto, setSingleAdjunto, getAdjuntoProyecto } =
    adjuntosContext;

  const { clearModal, modalComponent } = useContext(ModalContext);

  const { proyecto } = useContext(ProyectosContext);

  const { folder, getFolder } = useContext(FolderContext);

  const focused = adjuntosContext.adjunto;

  const idProyecto = proyecto ? proyecto.idProyecto : null;

  useEffect(() => {
    setupFileExtension();
    getAdjuntoProyecto(idProyecto, adjunto.idAdjunto, setSrc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  const isFocused = () => {
    if (focused && focused !== null) {
      return focused.idAdjunto === adjunto.idAdjunto;
    }
  };

  const setupFileExtension = () => {
    let currentOutline;
    let currentSource;
    switch (adjunto.tipo) {
      case "pdf":
        currentOutline = "pdf";
        currentSource = "pdf.svg";
        break;
      case "pptx":
        currentOutline = "ppt";
        currentSource = "pptx.svg";
        break;
      default:
        currentOutline = "image";
        currentSource = "image.svg";
    }
    setOutline(currentOutline);
    setSource(currentSource);
  };

  const confirmDelete = () => {
    modalComponent(
      "Eliminar Adjunto",
      <div>
        <p>¿Deseas eliminar {adjunto.nombre}?</p>
        <p>Esta acción NO puede deshacerse.</p>
        <button className="btn btn-danger" onClick={handleDeleteFile}>
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const handleUpdateCallback = () => {
    getFolder(idProyecto, folder.idFolder);
    clearModal();
  }

  const handleDeleteFile = () => {
    deleteAdjunto(idProyecto, adjunto.idAdjunto, handleUpdateCallback);
  };

  const handleUpdateFile = (renameTo) => {
    updateSingleAdjunto(idProyecto, adjunto.idAdjunto, renameTo, handleUpdateCallback);
  };

  const renameFile = () => {
    modalComponent(
      "Renombrar",
      <AdjuntoForm
        initialValue={adjunto.nombre}
        handleCancel={clearModal}
        handleSubmit={handleUpdateFile}
      />,
    );
  };

  const renderOptions = () => {
    // if (showOptions) {
    if (platform === "web") {
      return (
        <div className="dropdown-menu">
          <button
            className="dropdown-item btn btn-link btn-sm text-secondary text-start"
            onClick={renameFile}
          >
            <i className="fa fa-edit"></i> Renombrar
          </button>
          <button
            className="dropdown-item btn btn-link btn-sm text-danger text-start"
            onClick={confirmDelete}
          >
            <i className="fa fa-trash"></i> Eliminar
          </button>
        </div>
      );
    } else {
      return (
        <div className=" dropdown-menu">
          <button
            className="btn  btn-sm text-start dropdown-item"
            style={{ fontWeight: "400px" }}
            onClick={confirmDelete}
          >
            <i className="fa fa-download" style={{ color: "##db8c61" }}></i>{" "}
            Descargar
          </button>
          <button
            className="btn btn-sm text-start dropdown-item"
            onClick={renameFile}
          >
            <i className="fa fa-edit" style={{ color: "##db8c61" }}></i> Editar
            Nombre
          </button>
          <button
            className="btn btn-sm text-start dropdown-item"
            onClick={confirmDelete}
          >
            <i className="fa fa-trash" style={{ color: "##db8c61" }}></i> Borrar
          </button>
        </div>
      );
    }
    // }
  };

  const handleClick = () => {
    setSingleAdjunto(adjunto);
  };

  const renderImage = () => {
    if (outline === "pdf") {
      const pdfSource = adjunto.src !== null ? adjunto.src : src;
      return (
        <div className="adjunto-card-img" onClick={handleClick}>
          {pdfSource !== "" && (
            <Document file={pdfSource}>
              <Page pageNumber={1} className="m-auto" />
            </Document>
          )}
        </div>
      );
    }
    if (outline !== "image" || src === "") {
      return (
        <div
          className="adjunto-card-img d-flex justify-content-center align-items-center"
          onClick={handleClick}
        >
          <i className="fas fa-file fa-3x" />
        </div>
      );
    }
    return (
      <div
        className="adjunto-card-img"
        style={{
          backgroundImage: `url(${adjunto.src || src})`,
        }}
        onClick={handleClick}
      />
    );
  };

  const renderContent = () => {
    if (platform === "web") {
      return (
        <div
          className={`col-12 ${
            size === "sm" ? "col-md-6" : "col-md-6 col-lg-4 col-xl-3"
          } mb-3`}
        >
          <div
            className={`card adjunto-card br-20 outline-${
              isFocused() ? "selected" : outline
            }`}
          >
            {renderImage()}
            <div className="adjunto-card-footer">
              <div className="dropdown">
                <button
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn btn-link btn-options text-dark"
                >
                  <i
                    className="fa fa-ellipsis-v"
                    style={{ color: "#db8c61", fontSize: "20px" }}
                  />
                </button>
                {renderOptions()}
              </div>

              <div className="row align-items-center mx-0">
                <div className="col-3 pr-0">
                  <img
                    src={`${S3_ENDPOINT}/${source}`}
                    alt={adjunto.nombre}
                    className="mw-100 w-100 d-block m-auto"
                  />
                </div>
                <div className="col-8 overflow-hidden">
                  <p className="adjunto-card-title text-start bold mb-0">
                    {adjunto.nombre}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-6 px-1 col-md-6 col-lg-4 col-xl-3 mb-3">
          <div
            className={`card adjunto-card br-20 outline-${
              isFocused() ? "selected" : outline
            }`}
          >
            {renderImage()}

            <div className="adjunto-card-footer col-12">
              <div className="row align-items-center mx-0">
                {/* <div className="col-3 pr-0">
                <img
                  src={`${source}`}
                  alt="Archivo"
                  className="mw-100 w-100 d-block m-auto"
                />
              </div> */}
                <div className="col-8 overflow-hidden">
                  <p className="adjunto-card-title text-start bold mb-0">
                    {adjunto.nombre}
                  </p>
                </div>

                <div className="col-2 dropdown">
                  <button
                    className="btn btn-link text-dark btn-options-container"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-ellipsis-v"
                      style={{ color: "#db8c61", fontSize: "20px" }}
                    />
                  </button>
                  {renderOptions()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderContent();
};

export default AdjuntoCard;
