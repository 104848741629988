import React from "react";
import AdjuntoEntrada from "../bitacora/AdjuntoEntrada";

const ReporteContenido = ({ reporte }) => {
  const renderImagenes = () => {
    if (Array.isArray(reporte?.entradas)) {
      return reporte.entradas
        .filter(({ Adjunto }) => Adjunto && Adjunto !== null)
        .map(({ Adjunto }) => (
          <AdjuntoEntrada key={Adjunto.idAdjunto} adjunto={Adjunto} />
        ));
    }
  };

  return (
    <div className="row">
      <table className="table">
        <tbody>
          <tr className="border">
            <td>{renderImagenes()}</td>
          </tr>
          <tr className="border text-white bold bg-primary">
            <td>RESUMEN</td>
          </tr>
          <tr className="border">
            <td>
              <p className="resumen">{reporte?.resumen}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReporteContenido;
