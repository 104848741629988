import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import ImageInput from "../common/ImageInput";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import "../../css/FileUpload.css";

const FileUpload = ({ type, modifier, handleSubmit }) => {
  const [filePreviews, setFilePreviews] = useState([]);
  const [files, setFiles] = useState([]);
  const { spinner } = useContext(AdjuntosContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    if (files !== null && files.length > 0) {
      getFilesData(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const getFilesData = (files) => {
    let imgFiles = [];

    files.forEach((file) => {
      if (file.type.includes("image")) {
        imgFiles.push(file);
      }
    });

    pushImages(imgFiles);
  };

  const saveFiles = (filesLoaded) => {
    setFiles([...filesLoaded]);

    if (typeof modifier === "function") {
      modifier(filesLoaded);
    }
  };

  const pushImages = (files) => {
    const newImagesPreview = [];

    const readFiles = () => {
      const promises = files.map((file) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          let name = file.name;

          reader.onload = (e) => {
            let src = e.target.result;
            newImagesPreview.push({ name, src, type: "image" });
            resolve();
          };

          reader.readAsDataURL(file);
        });
      });

      return promises;
    };

    Promise.all(readFiles()).then((res) => {
      setFilePreviews(newImagesPreview);
    });
  };

  const handleCancel = () => {
    clearModal();
    setFiles(null);
  };

  const renderAdjuntos = () => {
    if (filePreviews)
      if (filePreviews.length > 0) {
        return filePreviews.map((adjunto, index) => (
          <div
            className="card border-0 position-relative me-2 mb-2 file-preview"
            style={{
              backgroundImage: `url(${adjunto.src})`,
              backgroundPosition: "center",
              display: "inline-block",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100px",
            }}
            key={index}
          >
            {/* <button
                variant="outline-danger"
                className="btn btn-danger"
                style={{ position: "absolute", bottom: 0, right: 0 }}
                onClick={() => handleDeleteFile(index)}
              >
                <i className="fa fa-trash" />
              </button> */}
          </div>
        ));
      }
  };

  const handleFileInput = (e) => {
    saveFiles(e.target.files);
  };

  return (
    <div className="container px-0 " style={{ minHeight: 40 }}>
      <div className="row my-3">
        {type === "image" ? (
          <ImageInput
            left={1}
            multiple
            hideImage
            icon="file"
            label="Archivo"
            modifier={saveFiles}
          />
        ) : (
          <input
            multiple
            type="file"
            className="form-control"
            onChange={(e) => handleFileInput(e)}
          />
        )}
      </div>

      <div
        className="d-flex position-relative justify-content-center w-100 
        flex-row flex-wrap mb-3 ms-0"
      >
        {renderAdjuntos()}
      </div>

      {typeof handleSubmit === "function" && (
        <div className="row mx-0 mt-3">
          <div className="col-6 text-end">
            <button
              className="btn btn-link w-100 text-muted"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </div>
          <div className="col-6 px-0">
            <button
              disabled={spinner}
              className="btn btn-primary w-100"
              onClick={() => handleSubmit(files)}
            >
              {spinner ? <div className="spinner-border"></div> : "Subir"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
