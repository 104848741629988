import React from "react";

const CheckBox = ({ checked, disabled, args, modifier }) => {
  //Modifier: An action to make changes in the parent component or through Redux
  function toggle() {
    handleModifier(!checked);
  }

  function handleModifier(value) {
    if (modifier) {
      if (args !== undefined) {
        if (Array.isArray(args)) {
          modifier(...args, value);
        } else modifier(args, value);
      } else modifier(value);
    }
  }

  return (
    <button
      className={
        (checked ? "checked" : "unchecked") +
        " btn btn-primary checkbox text-light me-2"
      }
      onClick={toggle}
      disabled={disabled}
      style={{ width: 25, height: 25, padding: 0 }}
    >
      <i className="fa fa-check small"></i>
    </button>
  );
};

export default CheckBox;
