import React, { useEffect, useState } from "react";
import CheckBox from "../common/CheckBox";
import useGoogleDrive from "../../hooks/useGoogleDrive";

const ConnectDriveFolder = ({ handleCancel, handleSave, folder }) => {
  const [selectedDriveFolder, setSelectedDriveFolder] = useState(null);
  const [query, setQuery] = useState("");

  const { fetching, driveFolders, fetchDriveFolders } = useGoogleDrive();

  useEffect(() => {
    fetchDriveFolders({ query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, query]);

  return (
    <div>
      <label>Buscar folder por nombre</label>
      <input
        type="text"
        value={query}
        className="form-control border mb-3"
        onChange={(e) => setQuery(e.target.value)}
      />
      {fetching && <div className="spinner-border" />}
      {driveFolders?.length === 0 && !fetching && (
        <p>No hay folders que coincidan con esta búsqueda.</p>
      )}
      {driveFolders?.map((folder) => (
        <div key={folder.id} className="p-3 border-top">
          <CheckBox
            checked={selectedDriveFolder === folder.id}
            modifier={() => setSelectedDriveFolder(folder.id)}
          />
          {folder.name}
        </div>
      ))}
      <div className="row">
        <div className="col-6">
          <div className="btn text-muted w-100" onClick={handleCancel}>
            Cancelar
          </div>
        </div>
        <div className="col-6">
          <button
            className="btn w-100 btn-primary"
            onClick={() => handleSave(folder, selectedDriveFolder)}
            disabled={selectedDriveFolder === null}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectDriveFolder;
