import api from "./api";

const route = "/ordenesCambio";

const OrdenesCambioService = {
  getOrdenesCambio: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/${idContrato}`),
  getSingleOrdenCambio: (idProyecto, idOrdenCambio) =>
    api.get(`${route}/${idProyecto}/orden/${idOrdenCambio}`),
  postOrdenCambio: (idProyecto, ordencambio) => api.post(`${route}/${idProyecto}`, { ...ordencambio }),
  putOrdenCambio: (idProyecto, ordencambio) => api.put(`${route}/${idProyecto}`, { ...ordencambio }),
  deleteOrdenCambio: (idProyecto, idOrdenCambio) => api.delete(`${route}/${idProyecto}/orden/${idOrdenCambio}`),
};

export default OrdenesCambioService;
