import React, { createContext, useReducer } from "react";
import AppConfigReducer from "../reducers/AppConfigReducer";
import AppConfigService from "../services/AppConfigService";
import { SET_CONFIG, SET_SINGLE_CONFIG } from "../types/appconfig";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";

const initialState = {
  spinner: false,
};

export const AppConfigContext = createContext(initialState);

export const AppConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppConfigReducer, initialState);

  const getAppConfig = () => {
    AppConfigService.getAppConfig()
      .then((res) => {
        const { config } = res.data;
        dispatch({ type: SET_CONFIG, payload: config });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAdminAppConfig = () => {
    AppConfigService.getAdminAppConfig()
      .then((res) => {
        const { config } = res.data;
        dispatch({ type: SET_CONFIG, payload: config });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setAppConfig = (key, value) => {
    dispatch({ type: SET_SINGLE_CONFIG, payload: { key, value } });
  };

  const saveAppConfig = async (key, value) => {
    return AppConfigService.putAppconfig(key, value);
  };

  const regenerateAssistant = async (idProyecto, callback) => {
    dispatch({ type: SHOW_SPINNER });
    AppConfigService.regenerateAssistant(idProyecto).then((res) => {
      dispatch({ type: HIDE_SPINNER });
      if(typeof callback === "function") callback();
    });
  };

  return (
    <AppConfigContext.Provider
      value={{
        ...state,
        getAppConfig,
        setAppConfig,
        saveAppConfig,
        getAdminAppConfig,
        regenerateAssistant,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};
