import React, { useContext } from "react";
import { ReactComponent as PDFIcon } from "../../assets/pdf.svg";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import useProyecto from "../../hooks/useProyecto";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "react-router-dom";

const AdjuntoEntrada = ({ adjunto }) => {

  const { setSingleAdjunto } = useContext(AdjuntosContext);
  const { idProyecto } = useProyecto();

  if (["pdf", "PDF"].includes(adjunto.tipo)) {
    return (
      <div
        onClick={() => setSingleAdjunto(adjunto)}
        className="row py-2 mb-2 mx-0 align-items-center bg-light br-10 border"
      >
        <div className="col-3">
          <PDFIcon />
        </div>
        <div className="col-9">
          <p className="bold text-dark mb-1">
            {adjunto.nombre}.{adjunto.tipo}
          </p>
          <Link
            to={`/obra/${idProyecto}/supervision/${adjunto.AdjuntoFolders[0]?.idFolder}`}
            className="small btn btn-sm px-0 d-inline-block text-capitalize text-dark text-decoration-underline"
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-eye me-2" />
            {adjunto.AdjuntoFolders[0]?.Folder?.nombre || "Folder"}
          </Link>
        </div>
      </div>
    );
  }
  
  if (["jpg", "png", "jpeg", "gif", "webp"].includes(adjunto.tipo)) {
    const source =
      adjunto.src === null
        ? `${S3_ENDPOINT}/files/proyectos/${adjunto.idAdjunto}.${adjunto.tipo}`
        : adjunto.src;
    return (
      <img
        src={source}
        alt={adjunto.nombre}
        onClick={() => setSingleAdjunto(adjunto)}
        className="adjunto-mensaje d-inline-block mb-2 me-2"
      />
    );
  }

  if (String(adjunto.tipo).includes("video")) {
    return (
      <video className="mw-100 w-100 d-block m-auto" controls playsInline>
        <source src={adjunto.src} />
      </video>
    );
  }

  if (String(adjunto.tipo).includes("audio")) {
    return (
      <audio className="mw-100 w-100 d-block m-auto mb-3" controls playsInline>
        <source src={adjunto.src} />
      </audio>
    );
  }

  return (
    <div className="row mx-0 mb-2 align-items-center">
      <div className="spinner-border"></div>
      <span className="ml-3">Cargando adjunto...</span>
    </div>
  );
};

export default AdjuntoEntrada;
