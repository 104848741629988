import api from "./api";

const route = "/reportes";

export default {
  getReportes: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getSingleReporte: (idProyecto, idReporte) => api.get(`${route}/${idProyecto}/${idReporte}`),
  postReporte: (Reporte) => api.post(route, { ...Reporte }),
  putReporte: (Reporte) => api.put(route, { ...Reporte }),
  sendReporte: (idProyecto, data) => api.post(`${route}/${idProyecto}/email`, { ...data }),
  deleteReporte: (idProyecto, idReporte) => api.delete(`${route}/${idProyecto}/${idReporte}`),
};
