import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../context/MenuContext";
import { AppConfigContext } from "../context/AppConfigContext";
import { ModalContext } from "../context/ModalContext";
import { getValue } from "../utils";
import AssistantRegenerate from "../components/assistant/AssistantRegenerate";

const AjustesOpenAI = ({ idProyecto }) => {
  const [initialContext, setInitialContext] = useState(null);
  const appconfig = useContext(AppConfigContext);
  const { getAdminAppConfig, setAppConfig, saveAppConfig } = appconfig;
  const { selectTabs, setSelected } = useContext(MenuContext);
  const { success, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    setSelected({ title: "bot" });
    if (!idProyecto) {
      selectTabs();
      getAdminAppConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appconfig.default_assistant_context && initialContext === null) {
      setInitialContext(appconfig.default_assistant_context);
    }
  }, [appconfig, initialContext]);

  const handleRegenerate = () => {
    modalComponent("Regenerar Asistentes", <AssistantRegenerate />);
  };

  const handleSuccess = () => {
    success("Configuración guardada");
    getAdminAppConfig();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const promises = [];
    ["openai_api_key", "default_assistant_context"].forEach((key) => {
      promises.push(saveAppConfig(key, getValue(appconfig, key)));
    });
    Promise.all(promises).then(handleSuccess);
  };

  const handleSubmitProyecto = () => {
    saveAppConfig(
      "default_project_assistant_context",
      getValue(appconfig, "default_project_assistant_context")
    ).then(handleSuccess);
  };

  const renderAjustes = () => {
    if (appconfig !== null) {
      if (idProyecto) {
        return (
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-12 col-md-4">
                <label>
                  Contexto Predeterminado para Asistentes de Proyectos
                </label>
              </div>
              <div className="col-12 col-md-8">
                <textarea
                  rows="20"
                  className="form-control mb-3"
                  value={getValue(
                    appconfig,
                    "default_project_assistant_context"
                  )}
                  onChange={(e) =>
                    setAppConfig(
                      "default_project_assistant_context",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleRegenerate}
            >
              <i className="fa fa-sync" /> Regenerar Asistente de Proyecto
            </button>
            <div className="container-fluid px-0">
              <button
                onClick={handleSubmitProyecto}
                type="submit"
                className="btn btn-primary mt-4"
              >
                <i className="fa fa-save me-2"></i>Guardar
              </button>
            </div>
          </div>
        );
      }
      return (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-4">
              <label>API Key de Open AI</label>
            </div>
            <div className="col-12 col-md-8">
              <input
                type="text"
                className="form-control mb-3"
                value={getValue(appconfig, "openai_api_key")}
                onChange={(e) => setAppConfig("openai_api_key", e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <label>Contexto Predeterminado para Asistentes</label>
            </div>
            <div className="col-12 col-md-8">
              <textarea
                rows="20"
                className="form-control mb-3"
                value={getValue(appconfig, "default_assistant_context")}
                onChange={(e) =>
                  setAppConfig("default_assistant_context", e.target.value)
                }
              />
            </div>
          </div>
          <div className="container-fluid px-0">
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleRegenerate}
            >
              <i className="fa fa-sync" /> Regenerar Asistente General
            </button>
          </div>
          <div className="container-fluid px-0">
            <button type="submit" className="btn btn-primary mt-4">
              <i className="fa fa-save me-2"></i>Guardar
            </button>
          </div>
        </form>
      );
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h2 pb-2 mb-3 border-bottom">Arqvisor Bot</h1>
      <div className="card p-4 shadow">{renderAjustes()}</div>
    </div>
  );
};

export default AjustesOpenAI;
