import React from "react";
import useProyecto from "../../hooks/useProyecto";
import GraficaFinanzas from "../finanzas/GraficaFinanzas";

const ReporteProgreso = ({ reporte }) => {
  const { proyecto } = useProyecto();

  return (
    <GraficaFinanzas
      hideTabs
      idProyecto={proyecto?.idProyecto}
      maxSemana={reporte?.semanaActual}
    />
  );
};

export default ReporteProgreso;
