import moment from "moment";
import React from "react";
import useProyecto from "../../hooks/useProyecto";

const ReporteGantt = ({ reporte }) => {
  const { proyecto } = useProyecto();

  const weeksArray =
    new Array(parseInt(proyecto?.semanasTotales)).fill(1) || [];

  const getMonthsArray = () => {
    const months = [];
    if (Array.isArray(weeksArray) && proyecto !== null) {
      const { fecha_inicio } = proyecto;
      let prevIndex = 0;
      let prevMonth = moment(fecha_inicio).month();
      for (const weeks in weeksArray) {
        const weekStart = moment(fecha_inicio).add(weeks, "weeks");
        const is_end = parseInt(weeks) === weeksArray.length - 1;
        if (weekStart.month() !== prevMonth || is_end) {
          const colSpan = weeks - prevIndex + (is_end ? 1 : 0);
          months.push(
            <th key={weekStart.format()} colSpan={colSpan}>
              {is_end
                ? weekStart.format("MMM")
                : weekStart.clone().subtract(1, "month").format("MMM")}
            </th>
          );
          prevIndex = weeks;
          prevMonth = weekStart.month();
        }
      }
    }
    return months;
  };

  const semanaInicial = moment(reporte?.fecha_inicio).diff(
    proyecto?.fecha_inicio,
    "weeks"
  );

  return (
    <div className="row">
      <div className="container-fluid px-0">
        <table className="table border">
          <thead className="bg-primary text-white">
            <tr>{getMonthsArray()}</tr>
          </thead>
          <tbody>
            <tr>
              {weeksArray.map((one, index) => {
                const semanaActual = parseInt(reporte?.semanaActual);
                const green = semanaInicial <= index && index < semanaActual;
                return (
                  <td
                    key={`${semanaActual}-${index}`}
                    style={{ height: 40 }}
                    className={`${green ? "bg-real" : "bg-light"}`}
                  ></td>
                );
              })}
            </tr>
            <tr>
              {weeksArray.map((one, index) => (
                <td key={`semana-${index}`} className="small">
                  {moment(proyecto?.fecha_inicio)
                    .utc()
                    .add(index, "weeks")
                    .format("DD-MMM")}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReporteGantt;
