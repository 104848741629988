import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_REPORTE,
  CREATE_REPORTE,
  REPORTES_RECEIVED,
  SET_PROPERTY_REPORTE,
} from "../types/reports";

const schema = {};

const ReportesReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case REPORTES_RECEIVED:
      return { ...state, reportes: payload };
    case SET_REPORTE:
      return { ...state, reporte: payload };
    case CREATE_REPORTE:
      return { ...state, reporte: schema };
    case SET_PROPERTY_REPORTE: {
      const { key, value } = payload;
      const reporte = { ...state.reporte };
      reporte[key] = value;
      return { ...state, reporte };
    }
    default:
      return { ...state };
  }
};

export default ReportesReducer;
