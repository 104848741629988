import React, { useContext, useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import Folders from "./Folders";
import Almacen from "./Almacen";
import Alertas from "./Alertas";
import Progreso from "./Progreso";
import Proyecto from "./Proyecto";
import Catalogo from "./Catalogo";
import Bitacora from "./Bitacora";
import Finanzas from "./Finanzas";
import Recorrido from "./Recorrido";
import OrderList from "./OrderList";
import PurchaseOrder from "./PurchaseOrder";
import Recorridos from "./Recorridos";
import Analiticas from "./Analiticas";
import Checklists from "./Checklists";
import Supervision from "./Supervision";
import SingleProduct from "./SingleProduct";
import useProyecto from "../hooks/useProyecto";
import MenuContratista from "./MenuContratista";
import SingleChecklist from "./SingleChecklist";
import ChecklistsMobile from "./ChecklistsMobile";
import { MenuContext } from "../context/MenuContext";
import { AlertasContext } from "../context/AlertasContext";
import { UsuariosContext } from "../context/UsuariosContext";
import { CapacitorContext } from "../context/CapacitorContext";
import ReportPreview from "./ReportPreview";
import SingleReporte from "./SingleReporte";
import Reportes from "./Reportes";
import AjustesOpenAI from "./AjustesOpenAI";
import AjustesGoogle from "./ajustes/AjustesGoogle";

const Obra = () => {
  const { idProyecto } = useParams();
  const { permiso, proyecto, fetchProyecto, clearSingleProyecto } =
    useProyecto();
  const { getUsuariosProyecto } = useContext(UsuariosContext);
  const { platform } = useContext(CapacitorContext);
  const { getAlertas } = useContext(AlertasContext);
  const { selectTabs } = useContext(MenuContext);

  useEffect(() => {
    getUsuariosProyecto(idProyecto);
    fetchProyecto(idProyecto);
    getAlertas(idProyecto);
    selectTabs(idProyecto);
    return clearSingleProyecto;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  const renderRoutes = () => {
    if (proyecto && proyecto !== null) {
      return (
        <Routes>
          <Route
            path={
              permiso === "contratista" && platform !== "web"
                ? "/progreso"
                : "/"
            }
            element={<Progreso idProyecto={idProyecto} />}
          />
          {permiso === "contratista" && platform !== "web" && (
            <Route path="/" element={<MenuContratista />} />
          )}
          <Route
            path="/alertas"
            element={<Alertas idProyecto={idProyecto} />}
          />
          <Route
            path="/bitacora"
            element={<Bitacora idProyecto={idProyecto} />}
          />
          <Route
            path="/proyecto"
            element={<Proyecto idProyecto={idProyecto} />}
          />
          <Route
            path="/almacen"
            element={<Almacen idProyecto={idProyecto} />}
          />
          <Route
            path="/almacen/producto/:idProducto"
            element={<SingleProduct />}
          />
          <Route
            path="/catalogo"
            element={<Catalogo idProyecto={idProyecto} />}
          />
          <Route
            path="/ordenes"
            element={<OrderList idProyecto={idProyecto} />}
          />
          <Route
            path="/orden/:idOrden"
            element={<PurchaseOrder idProyecto={idProyecto} />}
          />
          <Route
            path="/checklists"
            element={<Checklists idProyecto={idProyecto} />}
          />
          <Route
            path="/analiticas"
            element={<Analiticas idProyecto={idProyecto} />}
          />
          <Route
            path="/recorridos"
            element={<Recorridos idProyecto={idProyecto} />}
          />
          <Route
            path="/supervision"
            element={<Supervision idProyecto={idProyecto} />}
          />
          <Route
            path="/supervision/:idFolder"
            element={<Supervision idProyecto={idProyecto} />}
          />
          <Route
            path="/recorridos/:idTour"
            element={<Recorrido idProyecto={idProyecto} />}
          />
          <Route
            path="/finanzas/*"
            element={<Finanzas idProyecto={idProyecto} />}
          />
          <Route
            path="/checklists/:idChecklist"
            element={<SingleChecklist idProyecto={idProyecto} />}
          />
          <Route
            path="/folders"
            element={<Folders idProyecto={idProyecto} />}
          />
          <Route path="/reportes" element={<Reportes />} />
          <Route path="/reportes/generador" element={<ReportPreview />} />
          <Route path="/reportes/:idReporte" element={<SingleReporte />} />
          <Route
            path="/progreso"
            element={<ChecklistsMobile idProyecto={idProyecto} />}
          />
          <Route
            path="/bot"
            element={<AjustesOpenAI idProyecto={idProyecto} />}
          />
          <Route path="/google" element={<AjustesGoogle />} />
        </Routes>
      );
    }
    return <div className="spinner-border" />;
  };

  return (
    <div
      id="obra-view"
      className="container-fluid px-0 h-100"
      style={{
        overflowX: "hidden",
      }}
    >
      {renderRoutes()}
    </div>
  );
};

export default Obra;
