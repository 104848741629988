import {
  SET_ORDENCAMBIO,
  CREATE_ORDENCAMBIO,
  ORDENCAMBIOS_RECEIVED,
  SET_PROPERTY_ORDENCAMBIO,
} from "../types/ordenescambio";

const schema = {};

const OrdenesCambioReducer = (state, { type, payload }) => {
  switch (type) {
    case ORDENCAMBIOS_RECEIVED:
      return { ...state, ordenescambio: payload };
    case SET_ORDENCAMBIO:
      return { ...state, ordencambio: payload };
    case CREATE_ORDENCAMBIO:
      return { ...state, ordencambio: schema };
    case SET_PROPERTY_ORDENCAMBIO: {
      const { key, value } = payload;
      const ordencambio = { ...state.ordencambio };
      ordencambio[key] = value;
      return { ...state, ordencambio };
    }
    default:
      return { ...state };
  }
};

export default OrdenesCambioReducer;
