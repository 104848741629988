import React, { createContext, useReducer, useContext } from "react";
import ReportesService from "../services/ReportesService";
import ReportesReducer from "../reducers/ReportesReducer";
import {
  REPORTES_RECEIVED,
  SET_REPORTE,
  CREATE_REPORTE,
  SET_PROPERTY_REPORTE,
} from "../types/reports";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { useNavigate } from "react-router-dom";

const initialState = {
  reportes: null,
  reporte: null,
};

export const ReportesContext = createContext(initialState);

export const ReportesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReportesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const navigate = useNavigate();

  const getReportes = (idProyecto) => {
    ReportesService.getReportes(idProyecto)
      .then((response) => {
        const { reportes } = response.data;
        dispatch({ type: REPORTES_RECEIVED, payload: reportes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleReporte = (idProyecto, idReporte) => {
    ReportesService.getSingleReporte(idProyecto, idReporte)
      .then((response) => {
        const { reporte } = response.data;
        dispatch({ type: SET_REPORTE, payload: reporte });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setReporte = (reporte) => {
    dispatch({ type: SET_REPORTE, payload: reporte });
  };

  const createReporte = () => {
    dispatch({ type: CREATE_REPORTE });
  };

  const setPropertyReporte = (key, value) => {
    dispatch({ type: SET_PROPERTY_REPORTE, payload: { key, value } });
  };

  const sendReporte = (idProyecto, data) => {
    dispatch({ type: SHOW_SPINNER });
    ReportesService.sendReporte(idProyecto, data)
      .then(() => {
        success("Reporte enviado.");
        dispatch({ type: HIDE_SPINNER });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  }

  const saveReporte = (reporte, callback) => {
    dispatch({ type: SHOW_SPINNER });
    const postCall = isNaN(parseInt(reporte.idReporte));
    let service = ReportesService.putReporte;
    if (postCall) {
      service = ReportesService.postReporte;
    }
    service(reporte)
      .then((res) => {
        success("Reporte guardado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (postCall) {
          const { reporte } = res.data;
          const { idProyecto, idReporte } = reporte;
          navigate(`/obra/${idProyecto}/reportes/${idReporte}`);
        }
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteReporte = (idProyecto, idReporte, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ReportesService.deleteReporte(idProyecto, idReporte)
      .then(() => {
        success("Reporte deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <ReportesContext.Provider
      value={{
        ...state,
        setReporte,
        getReportes,
        saveReporte,
        sendReporte,
        deleteReporte,
        createReporte,
        getSingleReporte,
        setPropertyReporte,
      }}
    >
      {children}
    </ReportesContext.Provider>
  );
};
