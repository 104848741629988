import React, { useContext, useEffect } from "react";
import { RetrasosContext } from "../../context/RetrasosContext";
import GraficaFinanzas from "../finanzas/GraficaFinanzas";
import ChartCard from "../analiticas/ChartCard";
import ColorLegend from "../common/ColorLegend";
import Entradas from "../bitacora/Entradas";
import { Link } from "react-router-dom";

const ProgresoObra = ({ idProyecto, start_date, end_date }) => {
  const { retrasos, getRetrasos } = useContext(RetrasosContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  function fetchData() {
    getRetrasos(idProyecto, { start_date, end_date });
  }

  function renderComentarios() {
    if (Array.isArray(retrasos)) {
      if (retrasos.length === 0) {
        return <p>No hay retrasos registrados en la bitácora.</p>;
      }
      return <Entradas entradas={retrasos} />;
    }
  }

  const colors = [
    {
      title: "Avance Programado",
      color: "#007991",
      className: "programado-gradiente",
    },
    {
      title: "Real Pagos",
      color: "#007991",
      className: "pagos-gradiente",
    },
    {
      title: "Avance Real",
      color: "#007991",
      className: "real-finanzas-gradiente",
    },
  ];

  return (
    <ChartCard>
      <div className="row">
        <h4 className="mb-3">Progreso de Obra</h4>
        <div className="col-md-12 col-xl-8 px-0">
          <GraficaFinanzas idProyecto={idProyecto} />
        </div>
        <div className="col-md-12 col-xl-4 border-left-1 bg-white">
          <h5 className="font-weight-bold mb-0">Leyenda</h5>
          <div className="py-3">
            <ColorLegend colors={colors} />
          </div>
          <h5 className="bold">Comentarios</h5>
          <div
            className="card bg-light p-3"
            style={{ height: 250, overflow: "hidden" }}
          >
            {renderComentarios()}
          </div>
          <Link
            className="btn btn-outline-primary my-3"
            to={`/obra/${idProyecto}/bitacora`}
          >
            Ir a Bitacora
          </Link>
        </div>
      </div>
    </ChartCard>
  );
};

export default ProgresoObra;
