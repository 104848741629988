import React, { useContext, useState } from "react";
import ReactSwitch from "react-switch";
import { ReportesContext } from "../../context/ReportesContext";
import useProyecto from "../../hooks/useProyecto";

const ReporteSendForm = ({ idReporte, handleCancel }) => {
  const [email, setEmail] = useState(false);
  const [whatsapp, setWhatsApp] = useState(false);

  const { sendReporte } = useContext(ReportesContext);
  const { idProyecto } = useProyecto();

  const handleSubmit = () => {
    sendReporte(idProyecto, { idReporte, email, whatsapp });
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <label>¿Enviar reporte por email?</label>
        </div>
        <div className="col-6 text-end">
          <ReactSwitch checked={email} onChange={setEmail} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label>¿Enviar reporte al grupo de WhatsApp?</label>
        </div>
        <div className="col-6 text-end">
          <ReactSwitch checked={whatsapp} onChange={setWhatsApp} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">Cancelar</button>
        </div>
        <div className="col-6">
          <button onClick={handleSubmit} className="btn w-100 btn-primary">Guardar</button>
        </div>
      </div>
    </div>
  );
};


export default ReporteSendForm;