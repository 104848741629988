import { getValue } from "../utils";

export const obraTabs = (obraId, appConfig) => {
  const tabs = [
    {
      title: "Inicio",
      name: "inicio",
      link: "/",
      icon: "fa fa-home",
    },
    {
      title: "Cronograma",
      name: "cronograma",
      link: `/obra/${obraId}`,
      icon: "fas fa-chart-bar",
    },
  ];
  if (getValue(appConfig, "warehouse_enabled", "boolean")) {
    tabs.push({
      title: "almacén",
      name: "almacén",
      link: `/obra/${obraId}/almacen`,
      icon: "fa fa-box",
    });
  }
  if (getValue(appConfig, "analytics_enabled", "boolean")) {
    tabs.push({
      title: "Analíticas",
      name: "analiticas",
      link: `/obra/${obraId}/analiticas`,
      icon: "fa fa-chart-pie",
    });
  }
  if (getValue(appConfig, "checklists_enabled", "boolean")) {
    tabs.push({
      title: "Checklists",
      name: "checklists",
      src: "checklists.svg",
      link: `/obra/${obraId}/checklists`,
      icon: "fas fa-tasks",
    });
  }
  if (getValue(appConfig, "bitacora_enabled", "boolean")) {
    tabs.push({
      title: "Bitácora",
      name: "bitacora",
      link: `/obra/${obraId}/bitacora`,
      icon: "fa fa-comments",
    });
  }
  if (getValue(appConfig, "folders_enabled", "boolean")) {
    tabs.push({
      title: "Folders",
      name: "folders",
      link: `/obra/${obraId}/folders`,
      icon: "fa fa-folder",
    });
  }

  if (getValue(appConfig, "finances_enabled", "boolean")) {
    tabs.push({
      title: "Finanzas",
      name: "finanzas",
      link: `/obra/${obraId}/finanzas`,
      icon: "fas fa-money-check-alt",
    });
  }
  if (getValue(appConfig, "orders_enabled", "boolean")) {
    tabs.push({
      title: "Ordenes",
      name: "Ordenes",
      link: `/obra/${obraId}/ordenes`,
      icon: "fa fa-file-alt",
    });
  }
  if (getValue(appConfig, "tours_enabled", "boolean")) {
    tabs.push({
      title: "360",
      name: "recorridos",
      link: `/obra/${obraId}/recorridos`,
      icon: "fa fa-vr-cardboard",
    });
  }
  if (getValue(appConfig, "bot_enabled", "boolean")) {
    tabs.push({
      title: "Bot",
      name: "bot",
      link: `/obra/${obraId}/bot`,
      icon: "fas fa-robot",
    });
  }
  tabs.push({
    title: "Reportes",
    name: "reportes",
    link: `/obra/${obraId}/reportes`,
    icon: "fa fa-file",
  });
  tabs.push({
    title: "Google",
    link: `/obra/${obraId}/google`,
    name: "google",
    permiso: "admin",
    icon: "fab fa-google",
  });
  return tabs;
};

export const homeTabs = (appConfig) => {
  const tabs = [
    { title: "Obras", link: "/", name: "obras", icon: "fa fa-home" },
    {
      title: "Usuarios",
      link: "/usuarios",
      name: "usuarios",
      permiso: "admin",
      icon: "fa fa-users",
    },
    {
      title: "Permisos",
      link: "/permisos",
      name: "permisos",
      permiso: "admin",
      icon: "fa fa-user-shield",
    },
  ];
  if (getValue(appConfig, "proveedores_enabled", "boolean")) {
    tabs.push({
      title: "Proveedores",
      link: "/proveedores",
      name: "proveedores",
      permiso: "admin",
      icon: "fa fa-truck",
    });
  }
  if (getValue(appConfig, "warehouse_enabled", "boolean")) {
    tabs.push({
      title: "Almacén",
      link: "/almacen",
      name: "almacen",
      permiso: "admin",
      icon: "fa fa-box",
    });
  }
  if (getValue(appConfig, "clients_enabled", "boolean")) {
    tabs.push({
      title: "Clientes",
      link: "/clientes",
      name: "clientes",
      permiso: "admin",
      icon: "fas fa-user-tie",
    });
  }
  if (getValue(appConfig, "show_settings", "boolean")) {
    tabs.push({
      title: "Ajustes",
      link: "/ajustes",
      name: "ajustes",
      permiso: "admin",
      icon: "fa fa-cog",
    });
  }
  if (getValue(appConfig, "whatsapp_enabled", "boolean")) {
    tabs.push({
      title: "WhatsApp",
      link: "/whatsapp",
      name: "whatsapp",
      permiso: "admin",
      icon: "fab fa-whatsapp",
    });
  }
  if (getValue(appConfig, "bot_enabled", "boolean")) {
    tabs.push({
      title: "Bot",
      link: "/bot",
      name: "bot",
      permiso: "admin",
      icon: "fas fa-robot",
    });
  }
  tabs.push({
    title: "Plantillas",
    link: "/plantillas",
    name: "plantillas",
    permiso: "admin",
    icon: "fa fa-shapes",
  });
  tabs.push({
    title: "Estados",
    link: "/estados",
    name: "estados",
    permiso: "admin",
    icon: "fas fa-stream",
  });
  tabs.push({
    title: "Google",
    link: "/google",
    name: "google",
    permiso: "admin",
    icon: "fab fa-google",
  });
  return tabs;
};
