import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useProyecto from "../hooks/useProyecto";
import { ReportesContext } from "../context/ReportesContext";
import ReporteHeader from "../components/reportes/ReporteHeader";
import ReporteGantt from "../components/reportes/ReporteGantt";
import ReporteContenido from "../components/reportes/ReporteContenido";
import ReporteProgreso from "../components/reportes/ReporteProgreso";
import ReporteSendForm from "../components/reportes/ReporteSendForm";
import { ModalContext } from "../context/ModalContext";

const SingleReporte = () => {
  const { idProyecto } = useProyecto();
  const { idReporte } = useParams();

  const { reporte, getSingleReporte } = useContext(ReportesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getSingleReporte(idProyecto, idReporte);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idReporte]);

  const handlePDF = () => {
    if (reporte.Adjunto !== null) {
      return window.open(reporte.Adjunto.src, "_blank");
    }
    window.print();
  };

  const handleSend = () => {
    modalComponent(
      "Enviar Reporte",
      <ReporteSendForm idReporte={idReporte} handleCancel={clearModal} />
    );
  };

  return (
    <div className="container-fluid">
      <div id="report-header" className="row mb-4 align-items-center">
        <div className="col-10">
          <h1 className="h2">Reporte</h1>
        </div>
        <div className="col-2 text-end">
        <button className="btn me-2 btn-primary" onClick={handleSend}>
            <i className="fa fa-share" />
          </button>
          <button className="btn btn-primary" onClick={handlePDF}>
            <i className="fa fa-print" />
          </button>
        </div>
      </div>
      <div className="card shadow p-3 px-4">
        <ReporteHeader reporte={reporte} />
        <ReporteGantt reporte={reporte} />
        <ReporteProgreso reporte={reporte} />
        <ReporteContenido reporte={reporte} />
      </div>
    </div>
  );
};

export default SingleReporte;
