import React, { useContext, useEffect } from "react";
import { OrdenesCambioContext } from "../context/OrdenesCambioContext";
import useProyecto from "../hooks/useProyecto";
import usePreciario from "../hooks/usePreciario";
import { ModalContext } from "../context/ModalContext";
import OrdenCambioForm from "../components/ordenes/OrdenCambioForm";
import UserItem from "../components/users/UserItem";
import { Link } from "react-router-dom";
import ConfirmDelete from "../components/global/ConfirmDelete";
import BitacoraConcepto from "../components/conceptos/BitacoraConceptos";

const OrdenesCambio = () => {
  const { ordenescambio, getOrdenesCambio, deleteOrdenCambio } =
    useContext(OrdenesCambioContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { idProyecto, permiso } = useProyecto();
  const { idContrato } = usePreciario();

  useEffect(() => {
    getOrdenesCambio(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);


  const handleComments = (ordencambio) => {
    modalComponent(
      "Comentarios",
      <BitacoraConcepto
        idOrdenCambio={ordencambio.idOrdenCambio}
      />
    );
  };

  const handleCreate = () => {
    modalComponent(
      "Crear Orden de Cambio",
      <OrdenCambioForm handleCancel={clearModal} />
    );
  };

  const handleDelete = (ordencambio) => {
    modalComponent(
      "Editar Orden de Cambio",
      <ConfirmDelete
        message={`¿Estás seguro que deseas eliminar la Orden de Cambio ${ordencambio.folio}?`}
        handleDelete={() => deleteOrdenCambio(ordencambio.idOrdenCambio)}
        handleCancel={clearModal}
      />
    );
  };

  const renderOrdenes = () => {
    if (Array.isArray(ordenescambio)) {
      if (ordenescambio.length === 0) {
        return <p>No hay ordenes de cambio para este contrato</p>;
      }
      return (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="bg-light border">
                <th>Folio</th>
                <th>Anterior</th>
                <th>Nuevo</th>
                <th>Folder</th>
                <th>Usuario</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {ordenescambio.map((orden) => (
                <tr key={orden.idOrdenCambio}>
                  <td>{orden.folio}</td>
                  <td>
                    <Link
                      to={`/obra/${orden.idProyecto}/finanzas/${orden.idContrato}/concepto/${orden.anterior?.idConcepto}`}
                      className="text-underline"
                    >
                      <i className="fa fa-eye me-1" /> {orden.anterior?.clave}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/obra/${orden.idProyecto}/finanzas/${orden.idContrato}/concepto/${orden.nuevo?.idConcepto}`}
                      className="text-underline"
                    >
                      <i className="fa fa-eye me-1" /> {orden.nuevo?.clave}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/obra/${orden.idProyecto}/supervision/${orden.idFolder}`}
                      className="btn btn-light border"
                    >
                      <i className="fa fa-folder me-1" /> {orden.Folder?.nombre}
                    </Link>
                  </td>
                  <td>
                    <UserItem user={orden.Usuario} size="xs" />
                  </td>
                  <td>
                    <button className="btn btn-outline-primary me-2" onClick={() => handleComments(orden)}>
                      <i className="fa fa-comments" />
                    </button>
                    {["admin", "finanzas"].includes(permiso) && (
                      <button
                        onClick={() => handleDelete(orden)}
                        className="btn btn-outline-danger"
                      >
                        <i className="fa fa-trash" />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <div className="card shadow p-3">
      <div className="row align-items-center mb-2">
        <div className="col">
          <h3 className="h5 mb-0">Ordenes de Cambio</h3>
        </div>
        <div className="col text-end">
          <button onClick={handleCreate} className="btn btn-primary">
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      {renderOrdenes()}
    </div>
  );
};

export default OrdenesCambio;
