import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import useProyecto from "../../hooks/useProyecto";

const AssistantRegenerate = () => {
  const { idProyecto } = useProyecto();
  const { success, clearModal } = useContext(ModalContext);
  const { spinner, regenerateAssistant } = useContext(AppConfigContext);

  return (
    <div>
      <p>
        ¿Estás seguro que deseas regenerar el asistente predeterminado? Esto
        debe hacerse solo cuando cambias el contexto o cuando empieza a reducir
        el desempeño del asistente.
      </p>
      <div className="row">
        <div className="col-6">
          <button onClick={clearModal} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() =>
              regenerateAssistant(idProyecto, () => {
                success("Asistente regenerado");
                clearModal();
              })
            }
            className="btn btn-primary w-100"
            disabled={spinner}
          >
            {spinner ? <div className="spinner-border" /> : "Regenerar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssistantRegenerate;
